import React from "react"
import styled from "styled-components"
import { ItemsList } from "./utils"

const ReferenceDetails = ({ reference }) => (
  <CustomItemList>
    <li>Settore: {reference.sector.title}</li>
    <li>
      Categorie:{" "}
      {reference.category
        .map(category => <span key={category.id}>{category.name}</span>)
        .reduce((prev, curr) => [prev, ", ", curr])}
    </li>
    {reference.period && <li>Anno: {reference.period}</li>}
    <li>Luogo: {reference.location}</li>
  </CustomItemList>
)

const CustomItemList = styled(ItemsList)`
  li {
    margin-bottom: 0;
  }
`

export default ReferenceDetails
