import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { ItemsList, Container } from "./utils"

const ReferenceGallery = ({ gallery }) => (
  <CustomContainer>
    <CustomItemsList>
      {gallery.map(image => (
        <li key={image.originalId}>
          <img src={image.url} alt={image.alt} />
        </li>
      ))}
    </CustomItemsList>
  </CustomContainer>
)

const CustomContainer = styled(Container)`
  padding-bottom: 3rem;
`

const CustomItemsList = styled(ItemsList)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > li {
    width: 100%;
    margin: 0 0 ${DesignTokens.spacing[4]};
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 100%;
    }
    @media screen and (min-width: 769px) {
      margin: 2% 2% 0 0;
      width: calc(98% / 2);
      &:nth-child(-n + 2) {
        margin-top: 0;
      }
      &:nth-child(odd) {
        margin-right: 2%;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
    @media screen and (min-width: 1025px) {
      width: calc(96% / 3);
      &:nth-child(-n + 3) {
        margin-top: 0;
      }
      &:nth-child(even) {
        margin-right: 2%;
      }
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
`

export default ReferenceGallery
