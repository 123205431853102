import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/intro"
import { Container } from "../components/utils"
import DesignTokens from "../components/designTokens"
import ReferenceDetails from "../components/referenceDetails"
import ReferenceGallery from "../components/referenceGallery"
import Footer from "../components/footer"

const Reference = ({ data: { reference } }) => {
  return (
    <Layout>
      <SEO title={reference.client} lang="it" />
      <Header>
        <h1>{reference.client}</h1>
        <h2>{reference.project}</h2>
      </Header>
      <Intro title={reference.client} />
      <Details>
        <ReferenceDetails reference={reference} />
        <AdditionalDetails>
          <h4>Interventi realizzati</h4>
          <div
            dangerouslySetInnerHTML={{ __html: reference.description }}
          ></div>
        </AdditionalDetails>
      </Details>
      <ReferenceGallery gallery={reference.gallery} />
      <Footer />
    </Layout>
  )
}

const AdditionalDetails = styled.section`
  h4 {
    font-size: ${DesignTokens.fontSizes[6]};
    margin-bottom: ${DesignTokens.spacing[3]};
  }
  li {
    margin-bottom: 0;
  }
`

const Details = styled(Container)`
  display: flex;
  flex-direction: column;
  & > * {
    width: 100%;
  }
  margin-bottom: ${DesignTokens.spacing[5]};
  @media screen and (min-width: 769px) {
    margin-bottom: ${DesignTokens.spacing[7]};
    flex-direction: row;
    & > * {
      width: 50%;
    }
  }
`

export default Reference

export const query = graphql`
  query ReferenceQuery($slug: String!) {
    reference: datoCmsReference(slug: { eq: $slug }) {
      client
      project
      period
      location
      id
      slug
      description
      gallery {
        url
        alt
        originalId
      }
      sector {
        title
      }
      category {
        id
        name
      }
    }
  }
`
